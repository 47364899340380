import gql from "graphql-tag";

export const CreateAlertMutationDocument = gql`
    mutation CreateAlert($input: CreateAlertInput!) {
      createAlert(input: $input) {
        alert {
          nodeId
          id
          type
          message
        }
      }
    }
`;