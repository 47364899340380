import React, {useState} from 'react';
import styled from "styled-components";

import AccountQuery from '../../gql/Account';
import {useMutation, useQuery} from "@apollo/client";
import Spinner from "../Spinner";
import {Button, Buttons, Form, Select, TextArea} from "../form";
import Overlay from "../Overlay";
import {CreateAlertMutationDocument} from "../../gql/Alerts";

interface EditAccountProps {
    accountId: any,
    close: () => void
}

const EditAccount:React.FC<EditAccountProps> = ({accountId, close}) => {
    const {data, loading} = useQuery(AccountQuery, {
        variables: {
            id: accountId||''
        }
    });

    const [createAlert] = useMutation(CreateAlertMutationDocument);

    const closeHandler = (e?:any) => {
        if (typeof e?.preventDefault === 'function') e.preventDefault();

        if (e.target === e.currentTarget)
            close();
    };

    const [adding, setAdding] = useState(false);

    const addHandler = (e?:any) => {
        if (typeof e?.preventDefault === 'function') e.preventDefault();

        const form = e.target.closest('form');
        if (!form) return;

        const formData = new FormData(form);
        const type = formData.get('type') as string;
        const message = formData.get('message') as string;

        setAdding(true);

        createAlert({
            variables: {
                input: {
                    alert: {
                        accountId: accountId,
                        type: type,
                        message: message
                    }
                }
            }
        }).then(() => {
            close();
        }).finally(() => setAdding(false));
    }

    return (
        <Overlay>
            <Popup>{loading ? <Loading/> : (
                <StyledForm>
                    <h2>Add Alert For {data?.accountById?.firstName} {data?.accountById?.lastName}</h2>
                    <Select name="type" label="Type" required>
                        <option value="INFO">Info</option>
                        <option value="WARNING">Warning</option>
                        <option value="ERROR">Error</option>
                    </Select>
                    <TextArea name="message" label="Message" required/>
                    <Buttons>
                        <Button onClick={closeHandler} variation="transparent" disabled={adding}>Cancel</Button>
                        <Button onClick={addHandler} disabled={adding}>{adding ? 'Adding...' : 'Add Alert'}</Button>
                    </Buttons>
                </StyledForm>
            )}</Popup>
        </Overlay>
    )
};

export default EditAccount;

const StyledForm = styled(Form)`
    display: flex;
    flex-direction: column;
    grid-column-gap: 1rem;
    grid-row-gap: .5rem;
    
    h2 {
        margin-top: 0;
    }
`;

const Popup = styled.div`
    background: #fff;
    padding: 1rem;
`;

const Loading = styled.div.attrs({children:<Spinner/>})`
    padding: 2rem;
`;