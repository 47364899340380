import gql from "graphql-tag";

export const notesQueryDocument = gql`
    query Notes($topic: String!, $topicId: String!) {
      notes(condition: {topic: $topic, topicId: $topicId}) {
        nodes {
          nodeId
          id
          type
          note
          createdAt
          account {
            firstName
            lastName
          }
        }
      }
    }
`;

export const createNoteMutationDocument = gql`
    mutation CreateNote($input: CreateNoteInput!) {
      createNote(input: $input) {
        note {
          nodeId
          id
          type
          note
          createdAt
          account {
            firstName
            lastName
          }
        }
      }
    }
`;