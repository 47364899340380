import loginAsMutationDocument from "../gql/LoginAs";
import {apolloClient, version} from "../config";

let LOCAL_SERVER = '';

if (document.location.hostname === 'localhost') {
    [3000, 44080].forEach(async port => {
        if (LOCAL_SERVER)
            return;

        const local = `${document.location.protocol}//${document.location.hostname}:${port}`;

        fetch(local, {mode: 'no-cors'})
            .then(() => {
                LOCAL_SERVER = local;
                console.log(`local ui server detected on port ${port}`);
            })
            .catch(() => {
                // console.log('local ui server not detected');
                // console.error(e);
            });
    });
}

export default function loginAs(account:any) {
    return new Promise((resolve, reject) => {
        const name = (account.name || `${account.firstName} ${account.lastName}` || '').trim();

        if (window.confirm(`Login as ${name}?`)) {
            resolve(apolloClient.mutate({
                mutation: loginAsMutationDocument,
                variables: {
                    input: {
                        accountId: account.accountId || account.id
                    }
                }
            }).then(async ({data}) => {
                if (!(data && data.loginAs && data.loginAs.link))
                    return;

                const {loginAs: {link: {id}}} = data;

                let baseUri = sessionStorage.getItem('baseUri') ?? LOCAL_SERVER;

                if (!baseUri) {
                    switch (version) {
                        case 'staging':
                            baseUri = 'https://staging.rbnrewards.com';
                            break;
                        case 'production':
                            baseUri = 'https://rbnrewards.com';
                            break;
                        default:
                            baseUri = 'https://dev.rbnrewards.com';
                            break;
                    }
                }

                sessionStorage.setItem('baseUri', baseUri);

                const url = `${baseUri}/login/?token=${id}`;

                window.open(url, 'login-as');
            }));
        }
        else {
            reject();
        }
    });
}
